import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal, Container } from "react-bootstrap";

export default class OurVision extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  close = () => {
    this.setState({ showModal: false });
  };

  open = () => {
    this.setState({ showModal: true });
  };

  render() {
    return (
      <div>
        <Button variant="outline-light" size="lg" onClick={this.open}>
          Our Values
        </Button>

        <Modal
          show={this.state.showModal}
          onHide={this.close}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          scrollable="true"
          centered
        >
          <Modal.Header>
            <Modal.Title
              style={{
                textAlign: "center",
                color: "#49B544",
                font: "gotham bold"
              }}
              id="contained-modal-title-vcenter"
            >
              Our Values
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <p style={{ color: "#A6A6A6" }}>
                <b>Passion:</b> We put passion into our work to create more
                value and meaning with what we do. Our being passionate and
                committed to our work result to excellence and excellence
                delivers delightful customer experience. <br />
                <br />
                <b>Integrity:</b> We perform the right thing in doing business.
                We sincerely treat each other with trust and respect.
                <br />
                <br />
                <b>Teamwork:</b> We value the worth of each of our colleague’s
                contribution to the achievement of our objectives. We encourage
                open communication by being proactive in providing and receiving
                feedback.
                <br />
                <br />
                <b>Customer Centricity:</b> We provide intelligent solutions for
                our clients to create a positive and delightful experience, and
                drive repeat business leading to customer loyalty.
                <br />
                <br />
                <b>Care for the Company:</b> We go above and beyond to help the
                company achieve its goals. We care about productivity and the
                quality of our work. We employ sustainable business practices
                that contribute in achieving harmony within the workplace.
              </p>
            </Container>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
