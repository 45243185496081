import React, { Component } from "react";
// import PDFViewer from "pdf-viewer-reactjs";
import Topbar from "./mainpage/navbar";
import { Container, Image } from "react-bootstrap";
import "../privacyPolicy.css";


class Privacy extends Component {
  render() {
    return (
        <>
            {/* <Topbar /> */}
            <div
                style={{
                backgroundColor: "#F7F7F7",
                padding: "3%"
                }}
                className="bg-privacy"
            >
                <Container id="privacy_policy" className="container-privacy">
                    <Image src={
                        process.env.PUBLIC_URL +
                        "/images/Veridata-Wordmark-Colored-Black.png"
                        }
                        height="30"
                        width="auto"
                    />
                    <div className="green-bar">
                        <h1 className="header-title">Data Privacy Policy<br />
                        <span style={{ fontWeight: 100, fontSize: "small"}}>
                            December 21, 2018
                        </span>
                        </h1>
                    </div>
                    <div style={{ padding: "20px" }} />
                    <p>
                    Veridata Networks Inc. (Veridata) respects the privacy of the people we deal with
and we uphold high standards of privacy practice and security. This policy explains
how we handle personal information and how we interact with you online. 
                    </p>
                    <p>
                    Whenever we handle personal information, we take steps to ensure that appropriate
                    standards of privacy practice and security are applied.
                    </p>
                    <p>
                    The Veridata Privacy Policy sets out why we need to collect personal information,
how we collect it, what we do with it, how it’s stored and who we might share it
with. The Policy also describes how you can access or correct information we hold
about you, how you can ask further questions or make a complaint, and information
about our websites and online activities.
                    </p>
                    <p>
                    IF YOU ARE A MEMBER, PATIENT, OR EMPLOYEE OF A CLIENT THAT USES VERIDATA SERVICES OR PRODUCTS, THIS PRIVACY POLICY DOES NOT APPLY TO YOU OR YOUR USE OF THE SERVICES. PLEASE REFER TO THEIR RESPECTIVE PRIVACY POLICY, WHICH APPLIES TO THE COLLECTION, USE, PROCESSING AND RETENTION OF YOUR PERSONAL INFORMATION. INDIVIDUAL MEMBERS, PATIENTS, OR EMPLOYEES OF OUR CLIENTS WHO SEEK ACCESS TO THEIR DATA, OR WHO SEEK TO CORRECT, AMEND, OR DELETE INACCURATE DATA SHOULD DIRECT THEIR REQUESTS TO THOSE WHO ARE VERIDATA’S CLIENTS. VERIDATA CLIENTS ARE ABLE TO REMOVE AND UPDATE PERSONAL INFORMATION AND DATA WITHOUT VERIDATA’S INVOLVEMENT.
                    </p>

                    <h2>The Information We Collect</h2>
                    <h3>Employment Applications</h3>
                    <p>We collect personal information if you apply for a job with Veridata Networks Inc.</p>

                    <h3>Sensitive Information</h3>
                    <p>
                    Generally, we do not collect sensitive information about you unless required by law or where you consent for us to do so (only where it is relevant to your product). Sensitive information includes information relating to:
                    </p>
                    <ul>
                        <li>race</li>
                        <li>political or religious beliefs</li>
                        <li>sexual preferences</li>
                        <li>criminal convictions</li>
                        <li>membership in professional or trade associations</li>
                        <li>biometric and health information</li>
                    </ul>
                    <p>
                        We may collect information about your affiliation with certain organizations, such as professional associations.
                    </p>

                    <h2>Why We Collect Personal Information</h2>
                    <p>We collect personal information about you which is reasonably necessary to:</p>
                    <ul>
                        <li>maintain contact details</li>
                        <li>fulfill our legal obligations, such as those relating to taxation and anti-money
                        laundering</li>
                    </ul>
                    <p>Or as otherwise authorized by you.</p>
                    <p>Without such information, we may not be able to provide you with an appropriate
                    level of service.</p>

                    <h2>How We Collect Your Information</h2>
                    <p>
                    We collect personal information about you directly from you – this can be in person,
in documents you give us, from telephone calls, emails, your access to our website
or from transactions you mak
                    </p>
                    <p>
                        We may also collect your personal information from third parties including public
sources, your employer (or its affiliates), and service providers (including credit
reporting bodies and information service providers).</p>

                    <h2>How We Store Your Information</h2>
                    <p>
                    We keep personal information in electronic records at our premises and the
premises of our service providers, which may include storage on the cloud. Where
this occurs, we take steps to protect personal information against misuse or loss.
                    </p>
                    <p>
                    We also keep records of our interactions with you (including by telephone, email
                        and online), your transaction history and your enquiries or complaints.
                    </p>

                    <h2>How We Use Your Information</h2>
                    <p>We use personal information about you for the purpose for which it was provided to
                    us, including to:</p>
                    <ul>
                        <li>process transactions</li>
                        <li>administer and manage our products and services (including monitoring,
                            auditing, and evaluating those products and services)</li>
                        <li>model and test data</li>
                        <li>develop scores, risk assessments, and analytical tools</li>
                        <li>communicate with you and deal with or investigate any complaints or
                        inquiries</li>
                    </ul>
                    <p>We may also:</p>
                    <ul>
                        <li>de-identify your data for our own purposes including market research, data
                        analytics, new product development, and fraud mitigation</li>
                    </ul>
                    <p>While we may sometimes share personal information with companies we do
business with (e.g. in product development, joint venture arrangements or
distribution arrangements), we do not sell personal information for marketing
purposes to other organizations or allow such companies to do this.</p>

                    <h2>When Your Information Is Disclosed</h2>
                    <p>
                    We may share personal information within Veridata, and may disclose personal
                    information outside Veridata:
                    </p>
                    <ul>
                        <li>as required by law or regulations, such as those relating to anti-money
                        laundering and counter-terrorism financing or as required by a regulator</li>
                        <li>to our service providers, who provide services in connection with our products
and services (including archival, auditing, accounting, customer contact, legal,
business consulting, banking, payment, delivery, data processing, data
analysis, information broking, mailing, marketing, research, investigation,
insurance, identity verification, brokerage, maintenance, trustee, securitization,
website and technology services)</li>
<li>as contained in the terms and conditions of our product or service.</li>
                    </ul>

                    <h2>Keeping Information Accurate</h2>
                    <p>
                    We take reasonable steps to ensure that all information we hold is as accurate as is
possible. You are able to contact us at any time and ask for its correction if you feel
the information we have about you is inaccurate or incomplete.
                    </p>

                    <h2>Keeping Information Secure</h2>
                    <p>
                    We use security procedures and technology to protect the information we hold.
Access to and use of personal information within Veridata seeks to prevent misuse
or unlawful disclosure of the information.
                    </p>
                    <p>
                    If other organizations provide support services, we require them to appropriately
                    safeguard the privacy of the information provided to them.
                    </p>
                    <p>
                    Where the personal information we collect is no longer required, we delete the
information or permanently de-identify it in accordance with relevant laws and our
records management policy.
                    </p>

                    <h2>How you can access or correct your information</h2>
                    <p>
                    You can contact us to request access to or correction of your personal information.
In normal circumstances we will make the requested corrections to your information.
However, there may be some legal or administrative reasons to deny these requests.
If your request is denied, we will provide you with the reason why (if we can). Where
we decide not to make a requested correction to your personal information and you
disagree, you may ask us to make a note of your requested correction with the
information.
                    </p>

                    <h2>Dealing with Veridata Online</h2>
                    <p>
                    When you visit Veridata websites you will browse anonymously unless you have
logged into a client portal or accessed the website from a personalized
communication from Veridata.
                    </p>
                    <p>
                    For all visitors to our web site, we use cookies to collect anonymous information
such as the server your computer is logged on to, your browser type (for example,
Internet Explorer, Chrome or Firefox), and your IP address. (An IP address is a
number that is assigned to your computer automatically and required for using the
Internet). We may also derive the location associated with an IP address.
                    </p>
                    <p>
                    Once you have logged into a Veridata portal, accessed our website from an e-mail
or other personalized communication sent to you, or provided us with personal
information by completing a form online, we may be able to identify you and we
may combine that with other information in order to provide you with a better
online experience. If you would prefer not to be identified you can delete the
cookies and reconfigure the cookie preferences on your internet browser (see
below).
                    </p>

                    <h2>Cookies</h2>
                    <p>
                    A 'cookie' is a packet of information placed on a user's computer by a website for
record keeping purposes. While cookies are not considered personal information,
they are generally used on Veridata sites to:
                    </p>
                    <ul>
                        <li>access online services - if you log into our secure areas on our website we will
                        use cookies to authorize your access and save your preferences</li>
                        <li>monitor traffic - we use anonymous information to track how people are
using the Veridata site. This may include time of visit, pages visited and some
system information about the type of computer you are using</li>
<li>monitor your use of our website - if we have identified you as a result of you
logging into a Veridata client portal, accessing our website from a personalized communication or providing us with personal information by
completing a form online, we may use cookies to collect personal information
about you.</li>
                    </ul>
                    <p>
                    You can configure your browser to accept all cookies, reject all cookies, or notify you
when a cookie is sent. (Each browser is different, so check the Help menu of your
browser to learn how to change your cookie preferences).
                    </p>
                    <p>
                    If you disable the use of cookies on your web browser or remove or reject specific
cookies from Veridata websites or linked sites, then you may not be able to gain
access to all of the content and facilities on those websites.
                    </p>

                    <h2>Contact Us</h2>
                    <p>
                    If you have any questions or complaints regarding privacy or information handling,
                    please write to <a href="mailto:customercare@veridata.com.ph">customercare@veridata.com.ph</a> or contact us by phone. Please mark
communications to the attention of our Privacy Officer. We will respond to let you
know who will be handling your matter and when you can expect a further
response..
                    </p>

                    <p><em>Policy updated December 2018</em></p>
                </Container>

            </div>
        </>
    );
  }
}

export default Privacy;
