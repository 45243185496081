import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Image, Container, Col, Row } from "react-bootstrap";
import Ourvision from "../Modals/Whoweare/ourvision";
import Ourmission from "../Modals/Whoweare/ourmission";
import Ourvalues from "../Modals/Whoweare/ourvalues";
import Background from "../../images/VMV_BG-Photo.jpg";
import { Link } from "react-router-dom";

export default class Whoweare extends Component {
  render() {
    return (
      <div style={{ color: "#A6A6A6", backgroundColor: "#F7F7F7" }}>
        <div
          id="whoweare"
          style={{
            padding: "20px",
            textAlign: "center",
            background: "#FF9700",
            fontWeight: "bold",
            color: "white",
            font: "open sans"
          }}
        >
          <span>
            <h1>WHO WE ARE</h1>
          </span>
        </div>
        <Container>
          <Row>
            <Col md={6}>
              <p>
                <br />
                <br />
                <h2>
                  <span style={{ color: "#49B544", font: "gotham bold" }}>
                    <b>Our History</b>
                  </span>
                </h2>
                <br />
                <b>Veridata</b> was established in 2010 to provide Health
                Maintenance Organizations (HMOs) in the Philippines with an
                independent service provider for HMO members’ eligibility
                verification services. Veridata quickly built and deployed a
                secure network of wireless and wired terminals which were
                positioned in client HMO medical partners to address the high
                volume of patient requests in those facilities.
                <br />
                <br />
                Veridata’s differentiator was and continues to be our safeguards
                regarding confidentiality of information. Veridata does not keep
                a customer and member database, and instead sends all requests
                to the client HMO for verification, with Veridata providing the
                secure and redundant transport mechanism between the HMO and its
                medical partners. Veridata has continued that policy of
                information confidentiality in all our solutions, and our
                service is fully compliant with the requirements of the Data
                Privacy Act.
              </p>
            </Col>
            <Col md={6}>
              <p>
                <br />
                <br />
                <br />
                <br />
                <br />
                In 2015, Veridata introduced new solution areas by offering an
                HMO core system (HIMS), a Clinic Management System (CMS), and a
                Queue Management System (QMS). Veridata expanded our software
                and services offering through a partnership with Interfacing to
                offer BPM licenses and services. Veridata can also provide
                advisory services in software selection and implementation.
                <br />
                <br />
                Today, Veridata has six HMO clients: Asalus, Avega, Cocolife,
                HMI, MedicarePlus, and Insular Care, and is in discussions with
                other HMOs regarding implementation of HIMS, TVS, and CMS. With
                the future in mind, Veridata has completed the design of its
                next-generation platform services to integrate the major
                stakeholders in the healthcare industry, and we plan to have the
                initial modules in operation within the first half of 2020.
              </p>
            </Col>
          </Row>
        </Container>
        <div className="col-md-12">
          <br />
          <br />
          <Row>
            <div className="col-md-6" style={{ padding: "0px" }}>
              <Image
                src={
                  process.env.PUBLIC_URL + "/images/What-Sets-Us-Apart_IMG.png"
                }
                fluid
              />
            </div>
            <div className="col-md-6">
              <br />
              <br />
              <br />
              <br />
              <p style={{ color: "#A6A6A6" }}>
                <h2>
                  <span style={{ color: "#49B544", font: "gotham bold" }}>
                    <b>What Sets Us Apart</b>
                  </span>
                </h2>
                <br />
                <br />
                We recognize that the healthcare industry is experiencing
                accelerating complex changes.
                <br />
                <br />
                Over the years, Veridata has taken the time and effort to
                understand the intricacies and nuances in the relation ships
                between HMOs, the Medical Providers, clients, members and
                business partners.
                <br />
                <br />
                We will enable HMOs to participate in a digital healthcare
                community, to take advantage of their central position in the
                healthcare landscape, to unlock new business value, and to
                expand in new areas.
                <br />
                <br />
                {/* <a
                  href="/whoweare/presentation"
                  target="_blank"
                  rel="noopener noreferrer"
                > */}
                <Link to="/whoweare/presentation" target="_blank">
                  We Pave the Way for Digital Transformation
                </Link>
                {/* </a> */}
              </p>
            </div>
          </Row>
        </div>
        <div
          style={{
            backgroundImage: `url(${Background})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "cover",
            color: "white",
            left: "0",
            right: "0",
            bottom: "0",
            paddingTop: "2%",
            paddingBottom: "2%"
          }}
        >
          <Container>
            <Row className="justify-content-center">
              <Ourvision />
              <Ourmission />
              <Ourvalues />
            </Row>
            <Row
              style={{ marginTop: "50px" }}
              className="justify-content-center"
            >
              <Col xs={4}>
                <Image
                  src={process.env.PUBLIC_URL + "/images/VMV_Icon-1.png"}
                />
              </Col>
              <Col xs={4}>
                <Image
                  src={process.env.PUBLIC_URL + "/images/VMV_Icon-2.png"}
                />
              </Col>
              <Col xs={4}>
                <Image
                  src={process.env.PUBLIC_URL + "/images/VMV_Icon-3.png"}
                />
              </Col>
            </Row>
            <Row
              style={{ marginTop: "20px" }}
              className="justify-content-center"
            >
              <Col xs={4}>
                Build strong and solid healthcare linkages by having profound
                knowledge and broad experience in the industry.
              </Col>
              <Col xs={4}>
                Provide comprehensive, end-to-end quality service that exceeds
                customer needs.
              </Col>
              <Col xs={4}>
                Deliver proactive solutions based on process and technology.
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
