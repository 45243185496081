import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navbar, Nav } from "react-bootstrap";

export default class Topbar extends Component {
  render() {
    return (
      <>
        <Navbar bg="light" expand="lg" sticky="top">
          <Navbar.Brand href="#">
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/Veridata-Wordmark-Colored-Black.png"
              }
              width="auto"
              height="40"
              className="d-inline-block align-top"
              alt=""
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="justify-content-end" style={{ fontWeight: "bold" }}>
              <Nav.Link className="navbar-brand" href="#whatweoffer">
                WHAT WE OFFER
              </Nav.Link>
              <Nav.Link className="navbar-brand" href="#whoweare">
                WHO WE ARE
              </Nav.Link>
              <Nav.Link className="navbar-brand" href="#contactus">
                CONTACT US
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </>
    );
  }
}
