import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactGA from "react-ga";
import RouteConfig from "./components/router";
import "./index.css";

function initializeReactGA() {
  ReactGA.initialize("UA-153213161-1");
  ReactGA.pageview("/");
}

class App extends Component {
  render() {
    initializeReactGA();

    return (
      <>
        <RouteConfig />
      </>
    );
  }
}

export default App;
