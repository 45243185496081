import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";

export default class HmoModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  close = () => {
    this.setState({ showModal: false });
  };

  open = () => {
    this.setState({ showModal: true });
  };

  render() {
    return (
      <div>
        <Button
          variant="success"
          className="float-right"
          style={{ fontSize: "15px" }}
          size="lg"
          onClick={this.open}
        >
          MORE INFO
        </Button>

        <Modal
          show={this.state.showModal}
          onHide={this.close}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          scrollable="true"
          centered
        >
          <Modal.Body>
            <h4 style={{ color: "#49B544", fontWeight: "bold" }}>
              HMO Member Portal
            </h4>
            <br />
            <h5 style={{ fontWeight: "bold" }}>Accessibility Achieved </h5>
            <div style={{ color: "#919191" }}>
              <p>
                An online, self-service system that equips HMO Members to
                conveniently view their profile, their covered dependents,
                availment procedures, schedule of benefits and exclusions, and
                utilization.
              </p>
              <br />
              <p>
                <ul>
                  <li>
                    Ability to view accredited hospitals and clinics, doctors
                    and dentists
                  </li>
                  <li>Delightful experience in using the system</li>
                </ul>
              </p>
            </div>

            <div style={{ textAlign: "center" }} md={12}>
              <Button className="btn-success" onClick={this.close}>
                Close
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
