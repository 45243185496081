import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Ppt from "./ppt";
import MainPage from "./main";
import Privacy from "./privacy";

const routes = [
  {
    path: "/cor/privacy-policy",
    component: Privacy
  },
  {
    path: "/whoweare/presentation",
    component: Ppt
  },
  {
    path: "/",
    component: MainPage
  },
];

export default function RouteConfigExample() {
  return (
    <Router>
      <div>
        <Switch>
          {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
        </Switch>
      </div>
    </Router>
  );
}

function RouteWithSubRoutes(route) {
  return (
    <>
      <Route
        path={route.path}
        render={props => <route.component {...props} routes={route.routes} />}
      />
    </>
  );
}
