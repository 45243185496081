import PropTypes from "prop-types";
import React, { Component } from "react";
import { Form, Col, Button } from "react-bootstrap";

export default class SendEmailForm extends Component {
  state = {
    cli_name: "",
    company_name: "",
    email_add: "",
    contact_num: "",
    message: "",
    formSubmitted: false
  };

  //   static sender = "sample@email.com";

  handleCancel = () => {
    this.setState({
      cli_name: "",
      company_name: "",
      email_add: "",
      contact_num: "",
      message: ""
    });
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleSubmit = event => {
    event.preventDefault();

    const {
      REACT_APP_EMAILJS_RECEIVER: receiverEmail,
      REACT_APP_EMAILJS_TEMPLATEID: template,
      REACT_APP_EMAILJS_USERID: user
    } = this.props.env;

    this.sendFeedback(
      template,
      this.state.email_add,
      receiverEmail,
      this.state.cli_name,
      this.state.company_name,
      this.state.contact_num,
      this.state.message,
      user
    );

    this.setState({
      cli_name: "",
      company_name: "",
      email_add: "",
      contact_num: "",
      message: "",
      formSubmitted: true
    });
  };

  sendFeedback(
    templateId,
    senderEmail,
    receiverEmail,
    cli_name,
    company_name,
    contact_num,
    message,
    user
  ) {
    window.emailjs
      .send(
        "default_service",
        templateId,
        {
          senderEmail,
          receiverEmail,
          cli_name,
          company_name,
          contact_num,
          message
        },
        user
      )
      .then(res => {
        this.setState({
          formEmailSent: true
        });
      })
      // Handle errors here however you like
      .catch(err => console.error("Failed to send feedback. Error: ", err));
  }

  render() {
    return (
      <Form className="feedback-form" onSubmit={this.handleSubmit}>
        <Form.Row className="col-lg-16 frm-row">
          <Col xs={6}>
            <Form.Control
              size="lg"
              type="text"
              placeholder="Enter your name"
              id="cli_name"
              name="cli_name"
              value={this.state.cli_name}
              onChange={this.handleChange}
            />
          </Col>
          <Col xs={6}>
            <Form.Control
              size="lg"
              type="text"
              placeholder="Enter your company name"
              id="company_name"
              name="company_name"
              value={this.state.company_name}
              onChange={this.handleChange}
            />
          </Col>
        </Form.Row>
        <Form.Row className="col-lg-16 frm-row">
          <Col xs={6}>
            <Form.Control
              size="lg"
              type="text"
              placeholder="Enter your email"
              id="email_add"
              name="email_add"
              value={this.state.email_add}
              onChange={this.handleChange}
            />
          </Col>
          <Col xs={6}>
            <Form.Control
              size="lg"
              type="text"
              placeholder="Enter your contact number"
              id="contact_num"
              name="contact_num"
              value={this.state.contact_num}
              onChange={this.handleChange}
            />
          </Col>
        </Form.Row>
        <Form.Row className="col-lg-16 frm-row">
          <Col xs={12}>
            <Form.Control
              size="lg"
              as="textarea"
              placeholder="Enter your message"
              rows="10"
              id="message"
              name="message"
              value={this.state.message}
              onChange={this.handleChange}
            />
          </Col>
        </Form.Row>
        <Form.Row className="col-lg-16 frm-row">
          <Col xs={12}>
            <Button
              variant="danger"
              className="float-right"
              onClick={this.handleCancel}
            >
              CANCEL
            </Button>
            <Button
              variant="success"
              className="float-right"
              style={{ marginRight: "10px" }}
              value="Submit"
              type="submit"
            >
              SEND MESSAGE
            </Button>
          </Col>
        </Form.Row>
      </Form>
    );
  }
}

SendEmailForm.propTypes = {
  env: PropTypes.object.isRequired
};
