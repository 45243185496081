import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";

export default class BusinessModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  close = () => {
    this.setState({ showModal: false });
  };

  open = () => {
    this.setState({ showModal: true });
  };

  render() {
    return (
      <div>
        <Button
          variant="success"
          className="float-right"
          style={{ fontSize: "15px" }}
          size="lg"
          onClick={this.open}
        >
          MORE INFO
        </Button>

        <Modal
          show={this.state.showModal}
          onHide={this.close}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          scrollable="true"
          centered
        >
          <Modal.Body>
            <h4 style={{ color: "#49B544", fontWeight: "bold" }}>
              Business Process Management Services
            </h4>
            <br />
            <h5 style={{ fontWeight: "bold" }}>Intelligent Solutions </h5>
            <div style={{ color: "#919191" }}>
              <p>
                Addresses the growing needs of companies to reduce paperwork and
                elicit improved compliance by making complex processes simpler,
                agile and sustainable.
              </p>
              <br />
              <p>
                <ul>
                  <li>
                    Document enterprise “as-is” processes for ISO Certification
                    initiatives
                  </li>
                  <li>
                    Accelerate business process improvement initiatives using
                    the BPM Lean Methodology
                  </li>
                  <li>
                    Fast-track an end-to-end BPM project using an industry
                    leading BPM software technology (Interfacing – EPC ©)
                  </li>
                </ul>
              </p>
            </div>

            <div style={{ textAlign: "center" }} md={12}>
              <Button className="btn-success" onClick={this.close}>
                Close
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
