import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";

export default class HimsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  close = () => {
    this.setState({ showModal: false });
  };

  open = () => {
    this.setState({ showModal: true });
  };

  render() {
    return (
      <div>
        <Button
          variant="success"
          className="float-right"
          style={{ fontSize: "15px" }}
          size="lg"
          onClick={this.open}
        >
          MORE INFO
        </Button>

        <Modal
          show={this.state.showModal}
          onHide={this.close}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          scrollable="true"
          centered
        >
          <Modal.Body>
            <h4 style={{ color: "#49B544", fontWeight: "bold" }}>
              HMO Information Management System (HIMS)
            </h4>
            <br />
            <h5 style={{ fontWeight: "bold" }}>Ultimate Healthcare Hub </h5>
            <div style={{ color: "#919191" }}>
              <p>
                Enables the HMOs to facilitate value growth and enhance
                frictionless interaction between Medical Providers and their
                Members. Addresses the current and future needs of HMOs in the
                Philippines for a single integrated system to help manage their
                operations concerning clients, member servicing, medical
                providers, billing and claims processing.
              </p>
              <br />
              <p>
                <ul>
                  <li>
                    Organizational scalability through improved staff
                    productivity and cost efficiency.
                  </li>
                  <li>Laying the groundwork for meaningful data analytics</li>
                  <li>Superior customer service</li>
                  <li>Increased information security</li>
                  <li>Flexibility to handle new market requirements</li>
                </ul>
              </p>
            </div>

            <div style={{ textAlign: "center" }} md={12}>
              <Button className="btn-success" onClick={this.close}>
                Close
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
