import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Image } from "react-bootstrap";
import PlatformModal from "../Modals/platformmodal";

class Platform extends Component {
  render() {
    return (
      <div style={{ textAlign: "center" }}>
        <div
          id="whatweoffer"
          style={{
            padding: "20px",
            textAlign: "center",
            background: "#FF9700",
            fontWeight: "bold",
            color: "white"
          }}
        >
          <h1>WHAT WE OFFER</h1>
        </div>
        <h6>
          <p
            style={{
              textAlign: "center",
              color: "gray",
              font: "open sans",
              marginTop: "5%"
            }}
          >
            INTRODUCING
          </p>
        </h6>

        <h2
          style={{
            color: "#49B544",
            font: "gotham bold",
            textAlign: "center"
          }}
        >
          <b>The Veridata Healthcare Platform</b>
        </h2>
        <Image
          style={{
            maxWidth: "100%",
            maxHeight: "520px",
            marginTop: "20px"
          }}
          src={process.env.PUBLIC_URL + "/images/Asset 45@2x.png"}
        />
        <p style={{ margin: "0px 150px", color: "gray", font: "open sans" }}>
          <br />
          <br />A carefully architected, modular, integrated and collaborative
          platform of related applications harnessing new technologies to
          provide greater value to HMOs, their clients and members, medical
          providers, and their partners.
        </p>
        <br />
        <PlatformModal />
      </div>
    );
  }
}

export default Platform;

//  <Button className="btn-success"><span style={{color:"white"}}>MORE INFO</span></Button>
