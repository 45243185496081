import React, { Component } from "react";
import PDFViewer from "pdf-viewer-reactjs";
import Topbar from "./mainpage/navbar";
// import { Document, Page } from "react-pdf";
// import { pdfjs } from "react-pdf";

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     'pdfjs-dist/build/pdf.worker.min.mjs',
//     import.meta.url,
//   ).toString();

class Ppt extends Component {
  render() {
    return (
      <>
        <Topbar />
        <div
          style={{
            backgroundColor: "#F7F7F7",
            padding: "2%"
          }}
        >
          <div id="ppt">
            <PDFViewer
              document={{
                url:
                  process.env.PUBLIC_URL +
                  "/Digital_Transformation_112019_web.pdf"
              }}
              canvasCss="height: 100%, max-height: 100%"
            />
          </div>
        </div>
      </>
    );
  }
}

export default Ppt;
