import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";

export default class OurVision extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  close = () => {
    this.setState({ showModal: false });
  };

  open = () => {
    this.setState({ showModal: true });
  };

  render() {
    return (
      <div>
        <Button
          variant="outline-light"
          size="lg"
          onClick={this.open}
          style={{ marginRight: "20px" }}
        >
          Our Vision
        </Button>

        <Modal
          show={this.state.showModal}
          onHide={this.close}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          scrollable="true"
          centered
        >
          <Modal.Header>
            <Modal.Title
              style={{
                textAlign: "center",
                color: "#49B544",
                font: "gotham bold"
              }}
              id="contained-modal-title-vcenter"
            >
              Our Vision
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "#A6A6A6" }}>
              Bridging Healthcare and People through process and technology
            </p>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

//   <div style={{textAlign:"center"}} md={12}>
//                 <Button className="btn-success" onClick={this.close}>Close</Button>
//             </div>
