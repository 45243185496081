import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Image, Row, Col, Table } from "react-bootstrap";

export default class Nationwide extends Component {
  render() {
    return (
      <div>
        <Container>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <p
                style={{
                  textAlign: "center",
                  color: "#49B544",
                  font: "gotham bold"
                }}
              >
                <h2>
                  <b>Nationwide Presence</b>
                </h2>
              </p>
            </Col>
          </Row>
          <Row>
            <Table
              responsive
              borderless
              style={{ color: "#A6A6A6", padding: "0px" }}
            >
              <tr>
                <td>
                  <Image
                    src={process.env.PUBLIC_URL + "/images/NP_Icon-1.png"}
                  />
                </td>
                <td>
                  <span>
                    <h1 style={{ color: "#49B544", font: "gotham bold" }}>
                      <b>4,500,000</b>
                    </h1>
                    Membership Transactions Per Year
                  </span>
                </td>
                <td>
                  <Image
                    src={process.env.PUBLIC_URL + "/images/NP_Icon-2.png"}
                  />
                </td>
                <td>
                  <span>
                    <h1 style={{ color: "#49B544", font: "gotham bold" }}>
                      <b>750</b>
                    </h1>
                    TVS Points of Service
                  </span>
                </td>
                <td>
                  <Image
                    src={process.env.PUBLIC_URL + "/images/NP_Icon-3.png"}
                    fluid
                  />
                </td>
                <td>
                  <span>
                    <h1 style={{ color: "#49B544", font: "gotham bold" }}>
                      <b>2.85 Million</b>
                    </h1>
                    HMO Members
                  </span>
                </td>
              </tr>
            </Table>
          </Row>
        </Container>

        <div className="col-md-12">
          <Row>
            <div className="col-md-6" style={{ background: "#49B544" }}>
              <p style={{ color: "white", padding: "40px" }}>
                <h2>
                  <span>
                    <b>Who We Are</b>
                  </span>
                </h2>
                <br />
                <br />
                <Table borderless responsive style={{ color: "white" }}>
                  <tr>
                    <td>
                      <Image
                        src={
                          process.env.PUBLIC_URL +
                          "/images/Who-We-Are_Icon-1.png"
                        }
                        fluid
                      />
                    </td>
                    <td>
                      Veridata Networks is a technology and consulting company
                      focusing predominantly on the business side of the
                      Healthcare Industry.
                    </td>
                  </tr>
                  <br />
                  <tr>
                    <td>
                      <Image
                        src={
                          process.env.PUBLIC_URL +
                          "/images/Who-We-Are_Icon-2.png"
                        }
                        fluid
                      />
                    </td>
                    <td>
                      The company is operating a nationwide system used by
                      hospitals and clinics to verify the eligibility of
                      patients for HMO coverage.
                    </td>
                  </tr>
                  <br />
                  <tr>
                    <td>
                      <Image
                        src={
                          process.env.PUBLIC_URL +
                          "/images/Who-We-Are_Icon-3.png"
                        }
                        fluid
                      />
                    </td>
                    <td>
                      The company also engages in Business Process Management
                      (BPM) consulting, with clients in the healthcare,
                      education & energy sectors.
                    </td>
                  </tr>
                  <br />
                  <tr>
                    <td>
                      <Image
                        src={
                          process.env.PUBLIC_URL +
                          "/images/Who-We-Are_Icon-4.png"
                        }
                        fluid
                      />
                    </td>
                    <td>
                      Veridata provides comprehensive, end-to-end quality
                      service that exceeds customer needs.
                    </td>
                  </tr>
                  <br />
                  <tr>
                    <td>
                      <Image
                        src={
                          process.env.PUBLIC_URL +
                          "/images/Who-We-Are_Icon-5.png"
                        }
                        fluid
                      />
                    </td>
                    <td>
                      The Management team is comprised of experienced
                      professionals with concrete backgrounds in IT,
                      Telecommunications and HMOs.
                    </td>
                  </tr>
                </Table>
              </p>
            </div>
            <div className="col-md-6" style={{ padding: "0px" }}>
              <Image
                style={{ width: "100%", height: "100%" }}
                src={process.env.PUBLIC_URL + "/images/Who-We-Are_IMG.png"}
              />
            </div>
          </Row>
        </div>
      </div>
    );
  }
}

// <div className="col-md-12">
//     <br/><br/>
//     <div className="col-md-6" style={{background:"#49B544"}}>
//     <br/><br/>
//     <br/><br/>
//         <p style={{color:"white", padding:"50px"}}>
//             <h2><span><b>Who We Are</b></span></h2>
//             <br/><br/>
//             <Table
//                 borderless
//                 responsive
//                 style={{color:"white"}}
//             >
//                 <tr>
//                     <td><Image src={process.env.PUBLIC_URL + '/images/Who-We-Are_Icon-1.png'} fluid /></td><td>Veridata Networks is a technology and consulting company focusing predominantly on the business side of the Healthcare Industry.</td>
//                 </tr>
//                 <br/>
//                 <tr>
//                 <td><Image src={process.env.PUBLIC_URL + '/images/Who-We-Are_Icon-2.png'} fluid /></td><td>The company is operating a nationwide system used by hospitals and clinics to verify the eligibility of patients for HMO coverage.</td>
//                 </tr>
//                 <br/>
//                 <tr>
//                     <td><Image src={process.env.PUBLIC_URL + '/images/Who-We-Are_Icon-3.png'} fluid /></td><td>The company also engages in Business Process Management (BPM) consulting, with clients in the healthcare, education & energy sectors.</td>
//                 </tr>
//                 <br/>
//                 <tr>
//                     <td><Image src={process.env.PUBLIC_URL + '/images/Who-We-Are_Icon-4.png'} fluid /></td><td>Veridata provides comprehensive, end-to-end quality service that exceeds customer needs.</td>
//                 </tr>
//                 <br/>
//                 <tr>
//                     <td><Image src={process.env.PUBLIC_URL + '/images/Who-We-Are_Icon-5.png'} fluid /></td><td>The Management team is comprised of experienced professionals with concrete backgrounds
//                     in IT, Telecommunications and HMOs.
//                     </td>
//                 </tr>
//             </Table>
//         </p>
//     </div>
//     <div className="col-md-6" style={{width:"100%"}}>
//         <Image src={process.env.PUBLIC_URL + '/images/Who-We-Are_IMG.png'} fluid/>
//     </div>
// </div>
