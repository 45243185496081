import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";

export default class QmsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  close = () => {
    this.setState({ showModal: false });
  };

  open = () => {
    this.setState({ showModal: true });
  };

  render() {
    return (
      <div>
        <Button
          variant="success"
          className="float-right"
          style={{ fontSize: "15px" }}
          size="lg"
          onClick={this.open}
        >
          MORE INFO
        </Button>

        <Modal
          show={this.state.showModal}
          onHide={this.close}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          scrollable="true"
          centered
        >
          <Modal.Body>
            <h4 style={{ color: "#49B544", fontWeight: "bold" }}>
              Queue Monitoring System
            </h4>
            <br />
            <h5 style={{ fontWeight: "bold" }}>
              QRT (Queue, Resources, and Tasks) Specialist{" "}
            </h5>
            <div style={{ color: "#919191" }}>
              <p>
                Addresses the management of member queueing in high traffic
                clinics. It helps capture relevant queue information as inputs
                for better reports on productivity and performance, as well as
                patient wait and handling time.
              </p>
              <br />
              <p>
                <ul>
                  <li>Increased Customer Satisfaction</li>
                  <li>Cost Saving</li>
                </ul>
              </p>
            </div>

            <div style={{ textAlign: "center" }} md={12}>
              <Button className="btn-success" onClick={this.close}>
                Close
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
