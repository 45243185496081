import React, { Component, Fragment } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Carousel, Image } from "react-bootstrap";

export default class Banner extends Component {
  render() {
    return (
      <Fragment>
        <Carousel>
          <Carousel.Item>
            {/* <div
              style={{
                height: "50px",
                position: "absolute",
                color: "white",
                fontWeight: "bold",
                marginLeft: "10%",
                marginTop: "13%", //230px
                fontSize: "100"
              }}
            >
              <h1>
                Firing Up the Frontiers <br />
                of Digital Technology
              </h1>
            </div> */}
            <Image
              className="d-block w-100"
              src={
                process.env.PUBLIC_URL +
                "/images/11072019_Veridata-Website_Banners-2.png"
              }
              alt="First slide"
              style={{ maxHeight: "100%", maxWidth: "100%" }}
            />
          </Carousel.Item>
          <Carousel.Item>
            <Image
              className="d-block w-100"
              src={
                process.env.PUBLIC_URL +
                "/images/11072019_Veridata-Website_Banners-3.png"
              }
              alt="Third slide"
              style={{ maxHeight: "100%", maxWidth: "100%" }}
            />
          </Carousel.Item>
          <Carousel.Item>
            <Image
              className="d-block w-100"
              src={
                process.env.PUBLIC_URL +
                "/images/11072019_Veridata-Website_Banners-1 (1).png"
              }
              alt="Third slide"
              style={{ maxHeight: "100%", maxWidth: "100%" }}
            />
          </Carousel.Item>
        </Carousel>
      </Fragment>
    );
  }
}

// import SimpleImageSlider from "react-simple-image-slider";
// import AwesomeSlider from 'react-awesome-slider';
// import AwesomeSliderStyles from 'react-awesome-slider/src/styles';
// const images = [
//     { url: "images/Website_Banner_Who-We-Are.png" },
//     { url: "images/Website_Banner_What-We-Offer.png" },
//     { url: "images/Website_Banner_Contact-Us.png" },
// ];

//    <div>
//      <SimpleImageSlider
//         width={896}
//         height={604}
//          images={images}
//          slideDuration={.5}
//          navStyle={1}
//      />
//    </div>
// <AwesomeSlider
//     cssModule={AwesomeSliderStyles}
//     infinite={true}
//     bullets={false}
// >
//         <div data-src={process.env.PUBLIC_URL + '/images/Website_Banner_Who-We-Are.png'} />
//         <div data-src={process.env.PUBLIC_URL + '/images/Website_Banner_What-We-Offer.png'} />
//         <div data-src={process.env.PUBLIC_URL + '/images/Website_Banner_Contact-Us.png'} />
// </AwesomeSlider>
