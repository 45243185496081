import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Col, Row } from "react-bootstrap";
import SendEmailForm from "../form/sendEmailForm";
import PropTypes from "prop-types";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

class Contact extends Component {
  render() {
    return (
      <div>
        <div style={{ background: "#FF9700", color: "white" }}>
          <div
            id="contactus"
            style={{
              padding: "20px",
              textAlign: "center",
              fontWeight: "bold",
              font: "open sans",
              marginBottom: "5%"
            }}
          >
            <span>
              <h1>CONTACT US</h1>
            </span>
          </div>
        </div>
        <Container>
          <Row>
            <Col md={6}>
              <SendEmailForm env={this.props.env} />
            </Col>
            <Col md={6} className="vni-map" align="center">
              <div className="gmap_canvas">
                <iframe
                  width="400"
                  height="400"
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?width=720&height=400&hl=en&q=Valderrama%20Building%20Esteban%20St.+(Veridata%20Networks%20Inc.)&ie=UTF8&t=&z=19&iwloc=B&output=embed"
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                  title="vnimap"
                ></iframe>
              </div>
            </Col>
          </Row>
          <Row>
            <hr style={{ color: "#a6a6a6", width: "100%", height: "10px" }} />
          </Row>
          <Row style={{ marginBottom: "2%", marginTop: "2%" }}>
            <Col>
              <span
                style={{
                  color: "#49B544",
                  fontWeight: "bold",
                  textAlign: "left"
                }}
              >
                Office Address
              </span>
              <br />
              <p className="p-span">
                3/F Valderrama Bldg. 107 Esteban St.
                <br />
                Legaspi Village, Brgy. San Lorenzo,
                <br />
                Makati City 1229 Philippines
              </p>
            </Col>

            <Col>
              <span
                style={{
                  color: "#49B544",
                  fontWeight: "bold"
                }}
              >
                Trunkline
              </span>
              <br />
              <p className="p-span">
                +632-5328 2VNI
                <br />
                (+632-5328 2864)
              </p>
            </Col>

            <Col>
              <span
                style={{
                  color: "#49B544",
                  fontWeight: "bold"
                }}
              >
                Email
              </span>
              <br />
              <p className="p-span">marketing@veridata.com.ph</p>
            </Col>

            <Col>
              <span
                style={{
                  color: "#49B544",
                  fontWeight: "bold"
                }}
              >
                Careers
              </span>
              <br />
              <p className="p-span">
                Take the next step in your career with Veridata and be able to
                challenge the way things are done.
                <br />
                <br />
                <a
                  href="https://www.kalibrr.com/c/veridata-networks-inc/jobs"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#49B544", textDecoration: "underline" }}
                >
                  Apply Here
                </a>
              </p>
            </Col>
          </Row>
        </Container>
        
        <div style={{ background: "#49B544", color: "white" }}>
          <div style={{ position: "relative", width: "auto", height: "100%", overflow: "hi"}}>
            <Link to="/cor/privacy-policy" target="_blank">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/CORSeal.png"
                }
                width="auto"
                height="150"
                className="d-inline-block align-top"
                alt=""
                style={{
                  float: "left",
                  top: "10",
                  bottom: "0",
                  margin: "10px",

                }}
              />
            </Link>
          </div>
          <div
            style={{
              textAlign: "center",
              left: "0",
              right: "0",
              bottom: "0",
              padding: "60px"
            }}
          >
            <span>
              Copyright 2019. Veridata Networks Inc. All Rights Reserved.{" "}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

Contact.propTypes = {
  env: PropTypes.object.isRequired
};

export default Contact;
