import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Modal, Container } from 'react-bootstrap';

export default class PlatformModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            showModal: false
        }
    }

    close = () => {
        this.setState({ showModal: false });
    };

    open = () => {
        this.setState({ showModal: true });
    };

    render() {
    
    return (
        <div>
        <Button
            className="btn-success"
            onClick={this.open}
        >
            MORE INFO
        </Button>

        <Modal 
            show={this.state.showModal} 
            onHide={this.close}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            scrollable="true"
            centered
        >
            <Modal.Body>
            <Container style={{textAlign:"justify"}}>
            <h4 style={{color: "#49B544", font:"gotham bold"}}><b>Unlocking the value of the Healthcare Platform</b></h4><br/>
            <h5><b>Our Healthcare Experience</b> </h5>
            <p style={{color:"#A6A6A6"}}>
            Veridata understands how our healthcare providers operate.  From its inception, Veridata has been providing the HMOs with steadfast and reliable support services for member benefit verification.  Our team has accumulated over two decades of work exposure to HMO operations and our services have been expanded from the original swipe (Transaction Verification Services or TVS) to include Business Process Management advisory services, financial accounting system implementation, and now streaming – the Veridata Healthcare Platform.
            </p>
            <br/>
            <h5><b>We Safeguard our Clients’ Information</b>  </h5>
            <p style={{color:"#A6A6A6"}}>
            From the start, Veridata was set up to service multiple HMO clients.   The preservation of data security and confidentiality are of critical importance to us.  We have implemented measures to ensure information security.  We do not retain any personal identifiable information (PII) in our systems, and we restrict access to client related transactions.
            </p>
            <br/>
            <h5><b>Our Focus on Quality</b> </h5>
            <p style={{color:"#A6A6A6"}}>
            Veridata services are grounded on an unyielding commitment to deliver quality services to our clients to assist them in providing better service to their clients and members.  We believe that quality and process improvement are dynamic areas where access to best practice standards and methods can greatly contribute to the success of a project.  To this end, Veridata became a Professional Services Member of the American Productivity and Quality Center (APQC). APQC is the world’s foremost authority in benchmarking, best practices, process and performance improvement, and knowledge management. As a member, Veridata has access to APQC’s extensive library of research-based best practices and business drivers, benchmarking tools and data, and expertise. Where applicable, Veridata references these standards in the design and delivery of our solutions. Our internal practices regarding infrastructure, operations, and software development are based on ITIL standards in consideration of security, availability, accountability of our IT assets that support our customer-related and internal processes. 
            </p>
            <h5><b>We Focus on the Customer Experience</b>  </h5>
            <p style={{color:"#A6A6A6"}}>
            Everything we do is based on improving the experience of each stakeholder in the ecosystem of our clients. We make it our mission to understand the experience of each party, recognize how each role affects others in the process, and strive to help improve the overall experience for all stakeholders.  
            </p>
            <h5><b>Our Process Centric Approach</b> </h5>
            <p style={{color:"#A6A6A6"}}>
            This focus on the roles and process of the different organizational units helps ensure that our analysis is complete and accurate by reviewing and co-relating all processes across affected departments. This helps us build an enterprise process map, and facilitates the design and ensures completeness of the enterprise systems architecture and data model.  
            </p>
            <h5><b>Our Solutions are Designed to Help Our Clients Facilitate and Manage Change</b>  </h5>
            <p style={{color:"#A6A6A6"}}>
            Business processes and applications will change over time to respond to or enable changes in business requirements or the environment. Our solution sets incorporate BPM services and the Interfacing BPM tools to allow clients to define, model, test, communicate, and revise its processes as required, and most importantly, reflect these process and workflow changes in the client’s applications over time.  
            </p>
            <h5><b>We Support What We Deliver</b>  </h5>
            <p style={{color:"#A6A6A6"}}>
            We recognize that clients will expect us to support the solutions we deliver to them, and we have reinforced our support infrastructure, processes, and people to service our growing client base. We have upgraded our communications and Support Management System to manage all customer-reported matters in our ticketing system where these are tracked until the tickets are closed with agreement from the user. We are also implementing a mini-CRM to capture all customer-related information in a central database for use by support personnel; this database will contain pertinent information about a client’s infrastructure, setup, installed products, key contacts, and call history for ready access by our Veridata team.  
            </p>
            <h5><b>We Look for and Create Value</b>  </h5>
            <p style={{color:"#A6A6A6"}}>
            Very often, the customer’s stated requirements may not completely or accurately reflect the benefits or value behind their needs. Our BPM approach helps ensure that processes are aligned with business strategy, by identifying or defining performance metrics and the required measurement mechanisms. We test the value with the stakeholders to confirm assumptions and help validate the business case, and we can assist in establishing or confirming the value received after implementation. 
            </p>
            <h5><b>Long-Term Relationships Are Important to Us </b>  </h5>
            <p style={{color:"#A6A6A6"}}>
            We believe in building and maintaining long-term relationships with our partners and clients. Customer satisfaction and retention are our primary drivers. 
            </p>

            <div style={{textAlign:"center"}} md={12}>
                <Button className="btn-success" onClick={this.close}>Close</Button>
            </div>
            </Container>
            </Modal.Body>
        </Modal>
        </div>
    );
    }
  }
  