import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";

export default class EdxModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  close = () => {
    this.setState({ showModal: false });
  };

  open = () => {
    this.setState({ showModal: true });
  };

  render() {
    return (
      <div>
        <Button
          variant="success"
          className="float-right"
          style={{ fontSize: "15px" }}
          size="lg"
          onClick={this.open}
        >
          MORE INFO
        </Button>

        <Modal
          show={this.state.showModal}
          onHide={this.close}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          scrollable="true"
          centered
        >
          <Modal.Body>
            <h4 style={{ color: "#49B544", fontWeight: "bold" }}>
              Electronic Document Exchange (EDX1.0)
            </h4>
            <br />
            <h5 style={{ fontWeight: "bold" }}>Data Defined & Delivered </h5>
            <div style={{ color: "#919191" }}>
              <p>
                Links the eligibility and approval process to the Provider
                Billing and HMO Claims processes. Provides Notification,
                Acknowledgement, and Reconciliation functionalities.
              </p>
              <br />
              <p>
                <ul>
                  <li>
                    Significantly reduce turnaround time from Healthcare
                    Provider transaction completion to HMO claims adjudication
                  </li>
                  <li>
                    Eliminate the rate of rejected transactions due to
                    incomplete data and/or missing attachments via business
                    rule-specifiable system validations.
                  </li>
                  <li>
                    Ensure data integrity and security of the Billing Statements
                    of Account between the Healthcare Provider and HMO.
                  </li>
                  <li>
                    Adaptable to any Healthcare Provider system or HMO system
                  </li>
                </ul>
              </p>
            </div>

            <div style={{ textAlign: "center" }} md={12}>
              <Button className="btn-success" onClick={this.close}>
                Close
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
