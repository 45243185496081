import React, { Component } from "react";
import Topbar from "./mainpage/navbar";
import Banner from "./mainpage/banner";
import Platform from "./mainpage/platform";
import KeyComponents from "./mainpage/keycomponents";
import OtherProducts from "./mainpage/otherproducts";
import WhoWeAre from "./mainpage/whoweare";
import Nationwide from "./mainpage/nationwide";
import Clients from "./mainpage/ourclients";
import Contact from "./mainpage/contactus";
import Scrollup from "./mainpage/scrollup";
import { env } from "../config";

class MainPage extends Component {
  render() {
    return (
      <>
        <Scrollup />
        <Topbar />
        <Banner />
        <Platform />
        <br />
        <KeyComponents />
        <OtherProducts />
        <WhoWeAre />
        <br />
        <br />
        <Nationwide />
        <Clients />
        <Contact env={env} />
      </>
    );
  }
}

export default MainPage;
