import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Card } from "react-bootstrap";
import BusinessModal from "../Modals/otherproducts/businessmodal";
import QmsModal from "../Modals/otherproducts/qmsmodal";
import HmoModal from "../Modals/otherproducts/hmomodal";
import CmsModal from "../Modals/otherproducts/cmsmodal";

export default class OtherProducts extends Component {
  render() {
    return (
      <div
        style={{
          backgroundColor: "#F7F7F7",
          paddingBottom: "5%",
          paddingTop: "5%"
        }}
      >
        <Container>
          <div style={{ marginBottom: "5%" }} align="center">
            <h2>
              <span style={{ color: "#49B544", font: "gotham bold" }}>
                <b>Other Products</b>
              </span>
            </h2>
          </div>
          <Row style={{ marginBottom: "2%", fontSize: "15px" }}>
            <div className="col-md-6">
              <div
                className="d-flex p-0 col-example"
                style={{ backgroundColor: "#EDEDED", width: "inherit" }}
              >
                <img
                  className="align-self-center"
                  alt=""
                  style={{
                    height: "176px",
                    width: "auto",
                    display: "flex",
                    paddingLeft: "15px",
                    paddingRight: "15px"
                  }}
                  src={
                    process.env.PUBLIC_URL + "/images/Business Process-Icon.png"
                  }
                  fluid
                />
                <Card
                  style={{
                    width: "100%",
                    border: "none"
                  }}
                >
                  <Card.Body>
                    <Card.Title
                      style={{
                        color: "#49B544",
                        fontWeight: "bold",
                        fontSize: "20px"
                      }}
                    >
                      Business Process Management Services
                    </Card.Title>
                    <Card.Text>
                      <span style={{ fontWeight: "bold" }}>
                        Intelligent Solutions
                      </span>
                      <br />
                      <p
                        style={{
                          fontSize: "13px",
                          marginTop: "10px",
                          color: "#A6A6A6"
                        }}
                      >
                        Addresses the growing needs of companies to reduce
                        paperwork and elicit improved compliance by making
                        complex processes simpler, agile and sustainable.
                      </p>
                    </Card.Text>
                    <BusinessModal />
                  </Card.Body>
                </Card>
              </div>
            </div>

            <div className="col-md-6">
              <div
                className="d-flex p-0 col-example"
                style={{ backgroundColor: "#EDEDED", width: "inherit" }}
              >
                <img
                  className="align-self-center"
                  alt=""
                  style={{
                    height: "176px",
                    width: "auto",
                    display: "flex",
                    paddingLeft: "15px",
                    paddingRight: "15px"
                  }}
                  src={
                    process.env.PUBLIC_URL + "/images/Queue Monitoring-Icon.png"
                  }
                  fluid
                />
                <Card
                  style={{
                    width: "100%",
                    border: "none"
                  }}
                >
                  <Card.Body>
                    <Card.Title
                      style={{
                        color: "#49B544",
                        fontWeight: "bold",
                        fontSize: "20px"
                      }}
                    >
                      Queue Monitoring System
                    </Card.Title>
                    <Card.Text>
                      <span style={{ fontWeight: "bold" }}>
                        QRT (Queue, Resources, and Tasks) Specialist
                      </span>
                      <br />
                      <p
                        style={{
                          fontSize: "13px",
                          marginTop: "10px",
                          color: "#A6A6A6"
                        }}
                      >
                        Addresses the management of member queueing in high
                        traffic clinics. It helps capture relevant queue
                        information as inputs for better reports on productivity
                        and performance, as well as patient wait and handling
                        time.
                      </p>
                    </Card.Text>
                    <QmsModal />
                  </Card.Body>
                </Card>
              </div>
            </div>
          </Row>

          <Row style={{ marginBottom: "2%", fontSize: "15px" }}>
            <div className="col-md-6">
              <div
                className="d-flex p-0 col-example"
                style={{ backgroundColor: "#EDEDED", width: "inherit" }}
              >
                <img
                  className="align-self-center"
                  alt=""
                  style={{
                    height: "176px",
                    width: "auto",
                    display: "flex",
                    paddingLeft: "46px",
                    paddingRight: "46px"
                  }}
                  src={
                    process.env.PUBLIC_URL +
                    "/images/HMO Member Portal-Icon.png"
                  }
                  fluid
                />
                <Card
                  style={{
                    width: "100%",
                    border: "none"
                  }}
                >
                  <Card.Body>
                    <Card.Title
                      style={{
                        color: "#49B544",
                        fontWeight: "bold",
                        fontSize: "20px"
                      }}
                    >
                      HMO Member Portal
                    </Card.Title>
                    <Card.Text>
                      <span style={{ fontWeight: "bold" }}>
                        Accessibility Achieved
                      </span>
                      <br />
                      <p
                        style={{
                          fontSize: "13px",
                          marginTop: "10px",
                          color: "#A6A6A6"
                        }}
                      >
                        An online, self-service system that equips HMO Members
                        to conveniently view their profile, their covered
                        dependents, availment procedures, schedule of benefits
                        and exclusions, and utilization.
                      </p>
                    </Card.Text>
                    <HmoModal />
                  </Card.Body>
                </Card>
              </div>
            </div>

            <div className="col-md-6">
              <div
                className="d-flex p-0 col-example"
                style={{ backgroundColor: "#EDEDED", width: "inherit" }}
              >
                <img
                  className="align-self-center"
                  alt=""
                  style={{
                    height: "106px",
                    width: "auto",
                    display: "flex",
                    paddingLeft: "15px",
                    paddingRight: "15px"
                  }}
                  src={process.env.PUBLIC_URL + "/images/CMS-Icon.png"}
                  fluid
                />
                <Card
                  style={{
                    width: "100%",
                    border: "none"
                  }}
                >
                  <Card.Body>
                    <Card.Title
                      style={{
                        color: "#49B544",
                        fontWeight: "bold",
                        fontSize: "20px"
                      }}
                    >
                      Clinic Management System (CMS)
                    </Card.Title>
                    <Card.Text>
                      <span style={{ fontWeight: "bold" }}>
                        Clinic Operations Fortified
                      </span>
                      <br />
                      <p
                        style={{
                          fontSize: "13px",
                          marginTop: "10px",
                          color: "#A6A6A6"
                        }}
                      >
                        A flexible, integrated, and user-friendly solution to
                        support free-standing and multi-specialty clinics to
                        efficiently and effectively serve patients while
                        competently managing their administrative tasks.
                      </p>
                    </Card.Text>
                    <CmsModal />
                  </Card.Body>
                </Card>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}
