import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal, Container } from "react-bootstrap";

export default class OurVision extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  close = () => {
    this.setState({ showModal: false });
  };

  open = () => {
    this.setState({ showModal: true });
  };

  render() {
    return (
      <div>
        <Button
          variant="outline-light"
          size="lg"
          onClick={this.open}
          style={{ marginRight: "20px" }}
        >
          Our Mission
        </Button>

        <Modal
          show={this.state.showModal}
          onHide={this.close}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          scrollable="true"
          centered
        >
          <Modal.Header>
            <Modal.Title
              style={{
                textAlign: "center",
                color: "#49B544",
                font: "gotham bold"
              }}
              id="contained-modal-title-vcenter"
            >
              Our Mission
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <p style={{ color: "#A6A6A6" }}>
                • Build strong and solid healthcare linkages by having profound
                knowledge and broad experience in the industry.
                <br />
                • Provide comprehensive, end-to-end quality service that exceeds
                customer needs.
                <br />• Deliver proactive solutions based on process and
                technology.
              </p>
            </Container>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
