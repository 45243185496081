import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col, Image, Container } from "react-bootstrap";

export default class Clients extends Component {
  render() {
    return (
      <div
        style={{
          backgroundColor: "#F7F7F7",
          paddingBottom: "5%",
          paddingTop: "5%"
        }}
      >
        <div style={{ textAlign: "center", marginBottom: "2%" }}>
          <h2>
            <span
              style={{
                color: "#49B544",
                font: "gotham bold"
              }}
            >
              <b>Our Clients</b>
            </span>
          </h2>
        </div>
        <Container>
          <Row className="justify-content-md-center">
            <Col md={3}>
              <Image
                src={
                  process.env.PUBLIC_URL + "/images/AVEGA LOGO COLORED (1).png"
                }
                rounded
                height="99px"
                max-height="99px"
                width="auto"
              />
            </Col>
            <Col md={3}>
              <Image
                src={
                  process.env.PUBLIC_URL +
                  "/images/Cocolife Healthcare Logo - NEW.png"
                }
                rounded
                height="129px"
                max-height="129px"
                width="auto"
              />
            </Col>
            <Col md={3}>
              <Image
                src={
                  process.env.PUBLIC_URL +
                  "/images/HMI Logo High Resolution .png"
                }
                rounded
                height="109px"
                max-height="109px"
                width="auto"
              />
            </Col>
          </Row>
          <Row
            className="justify-content-md-center"
            style={{ marginTop: "5%" }}
          >
            <Col md={3}>
              <Image
                src={
                  process.env.PUBLIC_URL +
                  "/images/Inlife-Eagle-(Insular-Health-Care).png"
                }
                rounded
                height="119px"
                max-height="119px"
                width="auto"
              />
            </Col>
            <Col md={3}>
              <Image
                src={
                  process.env.PUBLIC_URL +
                  "/images/Intellicare Official Logo_PNG.png"
                }
                rounded
                height="69px"
                max-height="69px"
                width="auto"
              />
            </Col>
            <Col md={3}>
              <Image
                src={process.env.PUBLIC_URL + "/images/Med Plus Logo (1).png"}
                rounded
                height="69px"
                max-height="69px"
                width="auto"
              />
            </Col>
          </Row>
          <Row
            className="justify-content-md-center"
            style={{ marginTop: "5%" }}
          >
            <Col md={3}>
              <Image
                src={
                  process.env.PUBLIC_URL +
                  "/images/1coophealthlogo.png"
                }
                rounded
                height="129px"
                max-height="129px"
                width="auto"
                style={{ paddingLeft: '26px'}}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
