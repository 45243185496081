import React, {Component} from "react";
// import ScrollUpButton from "react-scroll-up-button";
import { Image } from 'react-bootstrap';
import ScrollToTop from 'react-scroll-to-top';
 
export default class Scrollup extends Component {
    render() {
        return (
            <div>
                {/* <ScrollUpButton 
                    StopPosition={0}
                    ShowAtPosition={500}
                    EasingType='easeOutCubic'
                    AnimationDuration={500}
                    ContainerClassName="AnyClassForContainer"
                    TransitionClassName="AnyClassForTransition"
                >
                    <Image style={{height: "100px", width:"70px"}} src={process.env.PUBLIC_URL + '/images/Scroll-To-Top_Icon.png'}/>
                </ScrollUpButton> */}
                <ScrollToTop smooth><Image style={{height: "100px", width:"70px"}} src={process.env.PUBLIC_URL + '/images/Scroll-To-Top_Icon.png'}/></ScrollToTop>
            </div>
        );
    }
}