import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Card, Row, Col, Image } from "react-bootstrap";
import TvsModal from "../Modals/keycomponentsmodals/tvsmodal";
import HimsModal from "../Modals/keycomponentsmodals/himsmodal";
import EdxModal from "../Modals/keycomponentsmodals/edxmodal";

export default class KeyComponents extends Component {
  render() {
    return (
      <div
        style={{
          backgroundColor: "#F7F7F7",
          paddingTop: "5%"
        }}
      >
        <Container>
          <div
            style={{
              textAlign: "center",
              color: "#49B544",
              font: "gotham bold"
            }}
          >
            <h2>
              <b>Key Components</b>
            </h2>
          </div>
          <Row
            style={{ marginBottom: "5%", marginTop: "50px" }}
            className="justify-content-center"
          >
            <Col xs={4}>
              <Card style={{ border: "0" }}>
                <Card.Img
                  variant="top"
                  src={process.env.PUBLIC_URL + "/images/TVS-Icon.png"}
                />
                <Card.Body>
                  <Card.Title>
                    <span style={{ color: "#49B544" }}>
                      <b>Transaction Verification Services</b>
                    </span>
                  </Card.Title>
                  <Card.Text>
                    <b>Power of Connectivity</b>
                    <br />
                    <br />
                    <span style={{ color: "#A6A6A6" }}>
                      Validates a member’s eligibility to avail of certain
                      healthcare services in a given facility.{" "}
                    </span>
                    <br />
                    <br />
                  </Card.Text>
                </Card.Body>
                <Card.Body>
                  <TvsModal />
                </Card.Body>
              </Card>
            </Col>
            <Col xs={4}>
              <Card style={{ border: "0" }}>
                <Card.Img
                  style={{ background: "#EEEDED" }}
                  variant="top"
                  src={process.env.PUBLIC_URL + "/images/HIMS-Icon.png"}
                />
                <Card.Body>
                  <Card.Title>
                    <span style={{ color: "#49B544" }}>
                      <b>HMO Information Management System </b>
                    </span>
                  </Card.Title>
                  <Card.Text>
                    <b>Healthcare Headmaster</b>
                    <br />
                    <br />
                    <p style={{ color: "#A6A6A6" }}>
                      Enables the HMOs to facilitate value growth and enhance
                      frictionless interaction between Medical Providers and
                      their Members.
                    </p>
                  </Card.Text>
                </Card.Body>
                <Card.Body>
                  <HimsModal />
                </Card.Body>
              </Card>
            </Col>
            <Col xs={4}>
              <Card style={{ border: "0" }}>
                <Card.Img
                  style={{
                    background: "#EEEDED"
                  }}
                  variant="top"
                  src={process.env.PUBLIC_URL + "/images/EDX-Icon.png"}
                />
                <Card.Body>
                  <Card.Title>
                    <span style={{ color: "#49B544" }}>
                      <b>Electronic Document Exchange</b>
                    </span>
                  </Card.Title>
                  <Card.Text>
                    <b>Data Defined & Delivered</b>
                    <br />
                    <br />
                    <p style={{ color: "#A6A6A6" }}>
                      Links the eligibility and approval process to the Provider
                      Billing and HMO Claims processes.
                    </p>
                    <br />
                    <br />
                  </Card.Text>
                </Card.Body>
                <Card.Body>
                  <EdxModal />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        <div
          className="row justify-content-md-center"
          style={{ background: "#49B544", margin: "0px" }}
        >
          <Col md={4} style={{ marginTop: "40px", marginBottom: "40px" }}>
            <Card style={{ borderRadius: "25px" }}>
              <Card.Body>
                <Card.Title style={{ color: "#49B544", font: "gotham bold" }}>
                  <Image
                    src={
                      process.env.PUBLIC_URL +
                      "/images/Value-Proposition_Icon.png"
                    }
                    rounded
                    height="64"
                    width="auto"
                  />{" "}
                  <b>Value Proposition</b>
                </Card.Title>
                <Card.Text style={{ color: "#A6A6A6", fontSize: "14px" }}>
                  Platform for the HMO Industry where participants interact with
                  value exchanges.
                  <br />
                  <br />
                  <ul>
                    <li>
                      Source of value is the information and interaction
                      facilitated.
                    </li>
                  </ul>
                  <br />
                  <br />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} style={{ marginTop: "40px", marginBottom: "40px" }}>
            <Card style={{ borderRadius: "25px" }}>
              <Card.Body>
                <Card.Title style={{ color: "#49B544", font: "gotham bold" }}>
                  <Image
                    src={
                      process.env.PUBLIC_URL +
                      "/images/Platform-Mindset_Icon.png"
                    }
                    height="64px"
                    width="auto"
                  />{" "}
                  <b>Platform Mindset</b>
                </Card.Title>
                <Card.Text style={{ color: "#A6A6A6", fontSize: "14px" }}>
                  “Our users interact with each other, using the software that
                  we create. Our products and services have no value unless
                  users use it.”
                  <br />
                  <br />
                  <ul>
                    <li>
                      Provides the integration platform, tools and services
                    </li>
                    <li>Encourages frictionless interaction</li>
                    <li>Drives network participation and traffic</li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </div>
      </div>
    );
  }
}
